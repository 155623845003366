<template>
  <div class="page-conference-detail" style="margin-top: -10px;margin-bottom: -80px;">
    <div class="inner-box" id="scrollTop">
      <!-- 菜单锚点 -->
      <div class="left">
        <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        :default-openeds="openeds"
      >
        <div v-for="item in menus" :key="`item${item.id}`">
          <el-submenu v-if="item.children.length > 0" :index="item.id">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span slot="title">
                {{ item.parentName }}
              </span>
            </template>
            <div>
              <el-menu-item-group>
                <el-menu-item
                  v-for="i in item.children"
                  :key="i.id"
                  :index="i.id"
                  @click="addMenu(i.id)"
                >
                  <span slot="title">
                    {{ i.name }}
                  </span>
                </el-menu-item>
              </el-menu-item-group>
            </div>
          </el-submenu>
          <template v-if="item.children.length <= 0">
            <el-menu-item :index="item.id" @click="addMenu(item.id)">
              <span slot="title">
                {{ item.parentName }}
              </span>
            </el-menu-item>
          </template>
        </div>
      </el-menu>
      </div>
      
      <!-- 文案 -->
      <div class="content" ref="content">
        <div class="box">
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll1' ? 'origin' : ''"
            id="scroll1"
          >
            M端登录
          </div>
          <div class="info top">
            <p>位置 ：登录首页</p>
            <p>登录方式区分两种验证码登录及密码登录</p>
            <p>1.验证码登录：使用注册商户的手机号+验证码登录系统</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question1.png')"
              fit="fill"
            ></el-image>
            <p>2.账号密码登录：</p>
            <p>
              使用商户注册手机号+密码登录，首次登录后需设置登录的账号和密码。
            </p>
            <p>
              当商户用户忘记登录的账号密码时，也可点击忘记密码进行重新设置登录密码。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question2.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question3.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll2' ? 'origin' : ''"
            id="scroll2"
          >
            合同管理
          </div>
          <div class="info top">
            <p>位置：合同管理/合同列表</p>
            <p>
              可通过该功能进行合同的【下载】、【预览】、合同草案【确认】、【修改】及合同的【签署】。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question4.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll3' ? 'origin' : ''"
            id="scroll3"
          >
            缴费管理
          </div>
          <div class="info top">
            <p>位置：缴费管理/缴费单管理</p>
            <p>若商户未签订合同，商户通过该功能缴纳平台使用费以及保证金。</p>
            <p>
              若商户已签订合同，商户通过该功能缴纳违约金、广告费、收入佣金、积分费用等。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question5.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll4' ? 'origin' : ''"
            id="scroll4"
          >
            店铺设置
          </div>
          <div class="info top">
            <p>位置：店铺管理/店铺列表/店铺配置</p>
            <p>
              临时闭店/开店：店铺遇到突发状况可选择【临时闭店】，用户端将展示用户闭店状态。闭店状态时可选择【开店】。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question6.png')"
              fit="fill"
            ></el-image>
            <p>
              设置自动接单：店铺可设置自动接单功能，当前设置自动接单功能为用户支付完成30分钟未接单，系统自动接单。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question7.png')"
              fit="fill"
            ></el-image>
            <p>
              首页设置：【首页设置】功能可设置店铺的背景图和环境图，展示于用户端。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question8.png')"
              fit="fill"
            ></el-image>
            <p>
              设置经营时间：可通过该功能配置店铺的经营时间，用户端在经营时间内展示“营业中”在店铺经营时间之外展示“已打烊”
            </p>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll5' ? 'origin' : ''"
            id="scroll5"
          >
            人员配置
          </div>
          <div class="info top">
            <p>位置：人员管理/人员列表</p>
            <p>
              1.点击【创建】添加员工信息，*为必填选项，完成后点击【保存】完成人员添加。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question9.png')"
              fit="fill"
            ></el-image>
            <p>
              2.选择角色后，进入授权角色页面，【创建】或【选择】人员所属职位以及所在的店铺，点击授权进入确认页面点击“授权”完成角色授权。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question10.png')"
              fit="fill"
            ></el-image>
            <p>
              3.若要创建角色，在选择角色界面，点击【创建角色】进入角色创建界面，填写角色名称，配置角色权限，完成配置。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question11.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll6' ? 'origin' : ''"
            id="scroll6"
          >
            配置服务人员
          </div>
          <div class="info top">
            <p>位置：店铺管理/服务人员管理</p>
            <p>1.该功能为服务业态使用</p>
            <p>
              2.点击【新增服务人员】，选择服务人员类型，判断下是否需要【关联】店铺人员，如果需要，请点击【关联】完成服务人员和店铺人员的【关联】。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question12.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question13.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question14.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll7' ? 'origin' : ''"
            id="scroll7"
          >
            配置店铺分类
          </div>
          <div class="info top">
            <p>位置：商品管理/商品基本信息管理/店铺商品分类管理</p>
            <p>
              1.点击【新增】添加分类，输入名称及排序添加店铺分类，点击【确定】店铺分类添加成功
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question15.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question16.png')"
              fit="fill"
            ></el-image>
            <p>
              2.此功能为【服务类型】商家使用，点击【新增】添加额外商品分类，输入名称及排序添加分类，点击【确定】分类添加成功，该商品应用于额外商品创建。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question17.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question18.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll8' ? 'origin' : ''"
            id="scroll8"
          >
            配置商品规格
          </div>
          <div class="info top">
            <p>位置：商品管理/基本商品信息管理/商品规格管理</p>
            <p>
              1.点击【新增】添加规格，填写所属类别、名称、属性名称、属性值等信息，点击【确定】商品规格添加成功。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question19.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question20.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question21.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll9' ? 'origin' : ''"
            id="scroll9"
          >
            配置自提点
          </div>
          <div class="info top">
            <p>位置：配送管理/自提点管理</p>
            <p>
              该功能为零售业态使用，点击【新增】添加商品的自提点，填写自提信息完成后点击【确定】添加自提点信息，完成添加。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question22.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question23.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll10' ? 'origin' : ''"
            id="scroll10"
          >
            配置运费模板
          </div>
          <div class="info top">
            <p>位置：配送管理/自提点管理</p>
            <p>
              该功能为零售商品功能，点击【新增】添加物流运费模板，若选择【免运费】则无需配置，若选择【自定义】则可配置默认的计费规则和特殊的计费规则。默认计费规则与特殊计费规则均按照地区进行划分，未设置地区的按照默认规则执行。已设置地区的按照地区的规则执行
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question24.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question25.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll11' ? 'origin' : ''"
            id="scroll11"
          >
            配置店铺信息
          </div>
          <div class="info top">
            <p>位置：店铺管理/店铺列表</p>
            <p>
              【编辑】功能可修改店面的基础信息、经营范围、店铺LOGO、店铺头像等店铺展示信息配置。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question26.png')"
              fit="fill"
            ></el-image>
            <p>
              【临时闭店】功能，若店铺遇到特殊情况，可操作临时闭店，暂时关闭店铺。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question27.png')"
              fit="fill"
            ></el-image>
            <p>【开店】功能，若店铺为临时闭店状态，可操作开店，打开店铺。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question28.png')"
              fit="fill"
            ></el-image>
            <p>
              【设置自动接单】功能，若店铺不能及时接单，可配置自动接单功能。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question29.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question30.png')"
              fit="fill"
            ></el-image>
            <p>【开店】功能，若店铺为临时闭店状态，可操作开店，打开店铺。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question31.png')"
              fit="fill"
            ></el-image>
            <p>【首页设置】可通过该功能设置店铺首页广告图和品牌图。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question32.png')"
              fit="fill"
            ></el-image>
            <p>【设置经营时间】功能，可通过该功能设置店铺的经营时间。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question33.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll12' ? 'origin' : ''"
            id="scroll12"
          >
            商户添加零售商品
          </div>
          <div class="info top">
            <p>位置：商品管理/零售商品管理/商户零售商品管理</p>
            <p>1.点击【新增】添加零售商品。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question34.png')"
              fit="fill"
            ></el-image>
            <p>2.选择商品的三级品类以及商品的类型。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question35.png')"
              fit="fill"
            ></el-image>
            <p>
              3.填写商品的名称、基本属性、规格、主图、展示图、详情描述等信息，编辑完成后点击【确定】完成商品的新增。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question36.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll13' ? 'origin' : ''"
            id="scroll13"
          >
            店铺添加零售商品
          </div>
          <div class="info top">
            <p>位置：商品管理/零售商品管理/店铺零售商品管理</p>
            <p>
              1.店铺通过【选择商品】的功能，从商户零售商品列表中拉取选择的零售商品到自己店铺的列表中，以SKU为最小单位。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question36.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question37.png')"
              fit="fill"
            ></el-image>
            <p>
              2.店铺商品上架前需配置商品销售价格、库存、配送方式、店铺分类，配置
              完成后可点击“立即上架”将商品上架到店铺中
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question38.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question39.png')"
              fit="fill"
            ></el-image>
            <p>
              3.也可以在店铺的商品列表页去修改商品的价格或者库存,进行上架或者下架的操作。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question40.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll14' ? 'origin' : ''"
            id="scroll14"
          >
            店铺添加服务商品
          </div>
          <div class="info top">
            <p>位置：商品管理/服务商品管理/店铺零售商品管理</p>
            <p>
              1.【新增服务商品】店铺通过该功能，创建服务商品，商品创建完成后会自动同步到商户服务商品列表中。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question41.png')"
              fit="fill"
            ></el-image>
            <p>2.选择商品品类以及商品的类型。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question42.png')"
              fit="fill"
            ></el-image>
            <p>
              3.选择或填写商品名称、分类、规格、价格、服务人员、商品图片、视频、详情、上架方式等商品信息。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question43.png')"
              fit="fill"
            ></el-image>
            <p>
              4.添加店铺收费项。店铺收费项是指独立的收费商品，直接添加不展示于用户端。商户可推送该商品到用户订单上，用户支付费用。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question44.png')"
              fit="fill"
            ></el-image>
            <p>
              5.添加额外收费商品项。额外的付费商品为除了基础付费商品外，C端消费者可选择额外的付费商品。如C端消费者购买【修锁】的服务型商品，也可同时选择加购【锁芯】这个实体物品。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question45.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question46.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll15' ? 'origin' : ''"
            id="scroll15"
          >
            添加额外商品组
          </div>
          <div class="info top">
            <p>位置：商品管理/服务商品管理/额外收费商品组</p>
            <p>
              1.【新增额外收费商品组】可将商户创建的【额外付费商品】进行分组，分组后再与服务型商品进行关联。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question47.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question48.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll16' ? 'origin' : ''"
            id="scroll16"
          >
            添加分销商品
          </div>
          <div class="info top">
            <p>位置：分销管理/分销商品管理</p>
            <p>1.点击【新增分销商品】，可将店铺商品设置为分销商品。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question49.png')"
              fit="fill"
            ></el-image>
            <p>2.选择商品，设置分佣比例，完成设置。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question50.png')"
              fit="fill"
            ></el-image>
            <p>3.若该分销商品有订单，则可通过【分销商品订单】进行【查询】</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question51.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll17' ? 'origin' : ''"
            id="scroll17"
          >
            客服
          </div>
          <div class="info top">
            <p>位置：客户咨询/客服工作台</p>
            <p>1.配置客服角色，可通过该功能进入客诉服务平台，处理客诉。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question52.png')"
              fit="fill"
            ></el-image>
            <p>位置：客户咨询/客服管理</p>
            <p>2.管理者可通过客服服务管理配置客服系统。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question53.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll18' ? 'origin' : ''"
            id="scroll18"
          >
            订单管理
          </div>
          <div class="info top">
            <p>位置：订单管理/待付款订单</p>
            <p>1.买家待付款订单，可通过该功能进行查看。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question54.png')"
              fit="fill"
            ></el-image>
            <p>位置：订单管理/零售订单列表</p>
            <p>2.可通过该功能查看零售订单列表。</p>
            <p>区分：</p>
            <p>待接单-待店铺接单订单。</p>
            <p>待发货-待店铺发货订单，店铺可配置发货。</p>
            <p>待收货-店铺已发货待C端消费者收货的订单。</p>
            <p>已完成-已完结的订单。</p>
            <p>已取消-店铺或C端消费者取消的订单。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question55.png')"
              fit="fill"
            ></el-image>
            <p>位置：订单管理/零售订单代下单</p>
            <p>
              3.零售商品可通过该功能代客下单，店铺代用户选择商品，填写用户手机号进行下单。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question56.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question57.png')"
              fit="fill"
            ></el-image>
            <p>位置：订单管理/服务订单列表</p>
            <p>4.可通过该功能查看服务订单列表</p>
            <p>待接单-用户已下单，待店铺接单。</p>
            <p>待服务-店铺已经接单。</p>
            <p>服务中-服务人员已到达服务地点，并触发【开始服务】。</p>
            <p>已完成-服务人员完成服务，用户确认服务。</p>
            <p>已取消-商户或用户取消的订单。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question58.png')"
              fit="fill"
            ></el-image>
            <p>位置：订单管理/服务单列表</p>
            <p>
              5.服务单列表为工作/服务人员工作所需的工作列表，区分已分配和未分配服务人员的订单，可做【分派服务人员】【修订订单】操作。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question59.png')"
              fit="fill"
            ></el-image>

            <p>位置：订单管理/服务订单代下单【未上线】</p>
            <p>
              5.服务商品可通过该功能代客下单。店铺代用户选择商品，填写用户手机号进行下单。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question60.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll19' ? 'origin' : ''"
            id="scroll19"
          >
            售后管理
          </div>
          <div class="info top">
            <p>位置：售后管理/售后管理</p>
            <p>
              1.订单若产生售后问题，可通过该功能进行处理，售后单由商家判定同意/拒绝。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question61.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll19' ? 'origin' : ''"
            id="scroll19"
          >
            售后管理
          </div>
          <div class="info top">
            <p>位置：售后管理/售后管理</p>
            <p>
              1.订单若产生售后问题，可通过该功能进行处理，售后单由商家判定同意/拒绝。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question61.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll20' ? 'origin' : ''"
            id="scroll20"
          >
            商品评价
          </div>
          <div class="info top">
            <p>位置：评价管理/商品评价列表</p>
            <p>通过商品维度查看商品评价</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question62.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll21' ? 'origin' : ''"
            id="scroll21"
          >
            订单评价
          </div>
          <div class="info top">
            <p>位置：评价管理/订单评价列表</p>
            <p>通过订单维度查看商品评价，订单可能为多个商品的组合。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question63.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll22' ? 'origin' : ''"
            id="scroll22"
          >
            服务人员评价
          </div>
          <div class="info top">
            <p>位置：评价管理/人员评价列表</p>
            <p>服务商品可通过该功能查看服务人员评价。该功能为服务业态功能</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question64.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll23' ? 'origin' : ''"
            id="scroll23"
          >
            商品评价
          </div>
          <div class="info top">
            <p>位置：账户管理/账户总览</p>
            <p>
              可通过该功能以店铺维度查看财务总览，也可按照公司筛选条件进行查询。收支明细查询功能按照店铺维度展示收支记录。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question65.png')"
              fit="fill"
            ></el-image>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question66.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll24' ? 'origin' : ''"
            id="scroll24"
          >
            缴费管理
          </div>
          <div class="info top">
            <p>位置：缴费管理/缴费单管理</p>
            <p>
              功能为店铺与国美家平台处理缴费的功能，由国美家推送账单，店铺缴纳费用。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question67.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll25' ? 'origin' : ''"
            id="scroll25"
          >
            发票管理功能
          </div>
          <div class="info top">
            <p>位置：发票管理/店铺发票抬头管理</p>
            <p>发票管理功能，可通过该功能配置店铺发票的抬头信息。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question68.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>

        <div>
          <div
            class="title1"
            :class="currentId === 'scroll26' ? 'origin' : ''"
            id="scroll26"
          >
            发票人员管理功能
          </div>
          <div class="info top">
            <p>位置：发票管理/店铺收票人管理</p>
            <p>可通过该功能配置店铺收票人的信息。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question69.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll27' ? 'origin' : ''"
            id="scroll27"
          >
            开具发票
          </div>
          <div class="info top">
            <p>位置：发票管理/开具发票</p>
            <p>
              可通过该功能可以【申请】或者【查看】国美家给商户开具的发票。
            </p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question70.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll28' ? 'origin' : ''"
            id="scroll28"
          >
            账单管理
          </div>
          <div class="info top">
            <p>位置：账单管理/缴费账单</p>
            <p>1.该功能为对账账单，财务人员可通过该功能进行对账</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question71.png')"
              fit="fill"
            ></el-image>
            <p>位置：账单管理/分账账单</p>
            <p>2.该功能为商户的订单分账列表，可通过该功能查询分账账单</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question72.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
        <div>
          <div
            class="title1"
            :class="currentId === 'scroll29' ? 'origin' : ''"
            id="scroll29"
          >
            结算管理
          </div>
          <div class="info top">
            <p>位置：结算管理/结算单查询</p>
            <p>通过该功能可查看，已妥投的可结算的账单明细。</p>
            <el-image
              class="questionImg"
              :src="require('@/assets/img/question73.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentId: 'scroll1',
      currentIndex: 0,
      openeds: ['1', '2'],
      menus: [
        { id: '1', href: 'scroll1', parentName: 'M端登录', children: [] },
        { id: '2', href: 'scroll2', parentName: '合同管理', children: [] },
        { id: '3', href: 'scroll3', parentName: '缴费管理', children: [] },
        { id: '4', href: 'scroll4', parentName: '店铺设置', children: [] },
        { id: '5', href: 'scroll5', parentName: '人员配置', children: [] },
        { id: '6', href: 'scroll6', parentName: '配置服务员', children: [] },
        { id: '7', href: 'scroll7', parentName: '配置店铺分类', children: [] },
        { id: '8', href: 'scroll8', parentName: '配置商品规格', children: [] },
        { id: '9', href: 'scroll9', parentName: '配置自提点', children: [] },
        { id: '10', href: 'scroll10', parentName: '配置运费模板', children: [] },
        { id: '11', href: 'scroll11', parentName: '配置店铺信息', children: [] },
        { id: '12', href: 'scroll12', parentName: '商户添加零售商品', children: [] },
        { id: '13', href: 'scroll13', parentName: '店铺添加零售商品', children: [] },
        { id: '14', href: 'scroll14', parentName: '店铺添加服务商品', children: [] },
        { id: '15', href: 'scroll15', parentName: '添加额外商品组', children: [] },
        { id: '16', href: 'scroll16', parentName: '添加分销商品', children: [] },
        { id: '17', href: 'scroll17', parentName: '客服', children: [] },
        { id: '18', href: 'scroll18', parentName: '订单管理', children: [] },
        { id: '19', href: 'scroll19', parentName: '售后管理', children: [] },
        { id: '20', href: 'scroll20', parentName: '商品评价', children: [] },
        { id: '21', href: 'scroll21', parentName: '订单评价', children: [] },
        { id: '22', href: 'scroll22', parentName: '服务人员评价', children: [] },
        // { id: '23', href: 'scroll23', parentName: '账户数据总览', children: [] },
        { id: '24', href: 'scroll24', parentName: '缴费管理', children: [] },
        { id: '25', href: 'scroll25', parentName: '发票管理功能', children: [] },
        { id: '26', href: 'scroll26', parentName: '发票人员管理功能', children: [] },
        { id: '27', href: 'scroll27', parentName: '开具发票', children: [] },
        { id: '28', href: 'scroll28', parentName: '账单管理', children: [] },
        { id: '29', href: 'scroll29', parentName: '结算管理', children: [] },
      ]
    };
  },
  methods: {
    addMenu (id) {
      let currentY = document.getElementById(this.currentId).scrollTop || document.body.scrollTop
      let targetY = document.getElementById(`scroll${id}`).offsetTop
      this.currentId = `scroll${id}`
      targetY -= 150
      console.log(currentY, targetY)
      this.scrollAnimation(currentY, targetY)
    },
    scrollAnimation (currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离 
      let needScrollTop = targetY - currentY
      let _currentY = currentY
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10)
        _currentY += dist
        this.$refs.content.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY)
        } else {
          this.$refs.content.scrollTo(_currentY, targetY)
        }
      }, 10)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../mall/assets/styles/base.scss';
::v-deep .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 0px 10px 30px 0 rgba(201, 201, 201, 0.2);
}
.page-conference-detail {
  .inner-box {
    max-width: 1200px;
    margin: 0 auto;
    //position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .href {
      color: #333333;
    }
    .left {
      height: 660px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0, 0, 0, 0.3);
        box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
    .content {
      width: 760px;
      height: 660px;
      background: #ffffff;
      border-radius: 4px;
      padding: 0 50px 0 50px;
      margin-left: 40px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0, 0, 0, 0.3);
        box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
      .title1 {
        margin-top: 40px;
        height: 24px;
        font-size: 18px;
        // font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 24px;
      }
      .title2 {
        margin-top: 40px;
        margin-bottom: 10px;
        height: 24px;
        font-size: 14px;
        // font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 24px;
      }
      .info {
        font-size: 12px;
        // font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 20px;
      }
      .top {
        margin-top: 40px;
      }
    }
  }
  .origin {
    color: #ee9118 !important;
  }
  .questionImg {
    width: 100%;
    // height: 500px;
    height: auto;
  }
}
::v-deep {
  .el-menu {
    border-right: 0;
  }
  .el-menu-vertical-demo {
    width: 300px;
    height: max-content;
    background: #ffffff;
    border-radius: 4px;
    //position: fixed;
  }
  .el-submenu__title {
    height: 50px;
  }
  .el-menu-item-group__title {
    padding-top: 0;
  }
  .el-menu-item.is-active {
    color: #ee9118;
  }
  .el-submenu__title:hover,
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #efefef;
  }
}
</style>